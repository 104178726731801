#app {
  min-height: 100%;
  display: flex;
  background: rgba(172, 185, 190, 0.14);
}

.card-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100vh; */
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 0;
  position: relative;
}

.headerSection {
  position: sticky;
  top: 0;
  z-index: 50;
}
.headerSectionTemplate2 {
  position: sticky;
  height: 100vh;
  top: 0;
  z-index: 50;
}

.contact-card {
  /* height: 100vh; */
  max-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background: #fff;
  width: 100%;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
}

.profile-container {
  position: absolute;
  top: 50px;
  transition: box-shadow 0.2s, opacity 0.2s;
  z-index: 2;
  min-width: 90%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 20px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.profile-container-Template2 {
  position: absolute;
  top: 15px;
  transition: box-shadow 0.2s, opacity 0.2s;
  z-index: 2;
  min-width: 90%;
  height: 100vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 20px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.profile-image-background {
  width: 8rem;
  height: 8rem;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-picture-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: inherit;
}
.profile-picture {
  border-radius: 50%;
  height: 7.1rem;
  width: 7.1rem;
  opacity: 1;
  object-fit: cover;
}

.logo-picture {
  border-radius: 5%;
  height: 80px;
  width: 80px;
  opacity: 1;
  border: none;
}

.action-control-container {
  position: fixed;
  bottom: -1px;
  background: linear-gradient(0deg, #fff 34.43%, rgba(255, 255, 255, 0) 100%);
  z-index: 100;
  height: 110px;
  width: inherit;
  max-width: 429px;
}
.save-button {
  height: 48px;
  width: 100%;
  border-radius: 20px !important;
  background: linear-gradient(99deg, #007fff 0%, #5342d6 100%);
}
.save-button-text {
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 6px 0px 6x;
  text-transform: capitalize;
}

.sync-button {
  margin: 0px 24px 0px 24px;
  border-radius: 10px !important;
}

.download-button {
  height: 48px;
  margin: 0px 24px 0px 24px;
  border-radius: 10px !important;
  background-color: #75b4e1;
}

.invisibleDiv {
  height: 15rem;
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0.8604691876750701) 100%
  );
  position: sticky;
  top: 0;
  z-index: 40;
}
.invisibleDivTemplate2 {
  height: 21rem;
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0.8604691876750701) 100%
  );
  position: sticky;
  top: 0;
  z-index: 40;
}
.invisibleDivTemplate2hide {
  height: 23rem;
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0.8604691876750701) 100%
  );
  position: sticky;
  top: 0;
  z-index: 40;
}

.commonAppContainerTepm2 {
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  height: 23rem;
  overflow: scroll;
  scrollbar-width: none;
  margin: 0px 0px 5rem 0px;
}

.bannerImage {
  width: 100%;
  height: 130px;
}
.poweredByImage {
  position: absolute;
  top: 20px;
  right: 20px;
}
/* Animations for the card */
.profile-shrink {
  animation: shrinkProfile 0.5s ease-in-out forwards;
}
.profile-expand {
  animation: expandProfile 0.5s ease-in-out forwards;
}
.profileBg-shrink {
  animation: shrinkProfileBg 0.5s ease-in-out forwards;
}
.profileBg-expand {
  animation: expandProfileBg 0.5s ease-in-out forwards;
}
.logo-shrink {
  animation: shrinkLogo 0.5s ease-in-out forwards;
}
.logo-expand {
  animation: expandLogo 0.5s ease-in-out forwards;
}
.hide-detail {
  animation: hideDetails 0.5s ease-in-out forwards;
}
.show-detail {
  animation: showDetails 0.5s ease-in-out forwards;
}
.bannerImageTemplate2 {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 27rem;
}

@keyframes shrinkProfile {
  0% {
    height: 7.1rem;
    width: 7.1rem;
  }
  100% {
    height: 6.1rem;
    width: 6.1rem;
  }
}
@keyframes expandProfile {
  0% {
    height: 6.1rem;
    width: 6.1rem;
  }
  100% {
    height: 7.1rem;
    width: 7.1rem;
  }
}

@keyframes shrinkProfileBg {
  0% {
    width: 8rem;
    height: 8rem;
  }
  100% {
    width: 7rem;
    height: 7rem;
  }
}
@keyframes expandProfileBg {
  0% {
    width: 7rem;
    height: 7rem;
  }
  100% {
    width: 8rem;
    height: 8rem;
  }
}

@keyframes shrinkLogo {
  0% {
    height: 80px;
    width: 80px;
  }
  100% {
    height: 68px;
    width: 68px;
  }
}
@keyframes expandLogo {
  0% {
    height: 68px;
    width: 68px;
  }
  100% {
    height: 80px;
    width: 80px;
  }
}

@keyframes hideDetails {
  0% {
    transform: translateY(0rem);
    opacity: 1;
    display: block;
  }
  100% {
    transform: translateY(-3rem);
    opacity: 0;
    display: none;
  }
}
@keyframes showDetails {
  0% {
    transform: translateY(-3rem);
    opacity: 0;
    display: none;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
    display: block;
  }
}
